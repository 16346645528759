<template>
  <div class="GovernmentWeekly">
    <div class="wrap">
      <h2>整体规范概况：</h2>
      <div class="targetCard">
        <p class="title">Paas托管能力总数</p>
        <p class="number">{{ deposit_total_num }}</p>
      </div>
      <div class="targetCard">
        <p class="title">Paas共享能力总数</p>
        <p class="number">{{ share_total_num }}</p>
      </div>
      <div class="targetCard">
        <p class="title">整体达标量（托管类型）</p>
        <p class="number">{{ total_y_num }}</p>
      </div>
      <div class="targetCard">
        <p class="title">总体规范率（托管类型）</p>
        <p class="number">{{ total_standard }}%</p>
      </div>
      <div class="explain_box">
        <p class="spec">治理规范说明：</p>
        <p>整体达标量：同时满足文档规范、接口规范视为整体达标；</p>
        <p>
          文档规范：满足接口描述（含：能力描述及服务类型）、输入参数（含：参数名、必填项、类型、描述）、输出参数（含：参数名、类型参数说明）、示例展示（含：请求示例、响应示例)；
        </p>
        <p>接口规范：满足能力接口调用正常；</p>
        <p>体验规范：满足能力Demo正常体验；</p>
        <p>评测规范：满足能力压测且QPS大于0。</p>
      </div>
      <h2>团队规范概况：</h2>
      <div class="table_box">
        <el-table
          :data="tableData"
          border
          stripe
          style="width: 100%"
          :header-cell-style="{
            fontWeight: 600,
            color: '#FFF',
            background: '#5B8FF9',
          }"
        >
          <el-table-column type="index" width="50" label="序号" align="center">
          </el-table-column>
          <el-table-column prop="team" label="团队名称"> </el-table-column>
          <el-table-column
            prop="total_num"
            label="能力总数"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="percentage"
            label="文档达标量/达标率"
            width="180"
            align="center"
          >
            <template slot-scope="total_contact">
              <span>{{
                total_contact.row.doc_y_num +
                "/" +
                total_contact.row.doc_percentage
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="percentage"
            label="接口达标量/达标率"
            width="180"
            align="center"
          >
            <template slot-scope="total_contact">
              <span>{{
                total_contact.row.interface_y_num +
                "/" +
                total_contact.row.interface_percentage
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="percentage"
            label="体验达标量/达标率"
            width="180"
            align="center"
          >
            <template slot-scope="total_contact">
              <span>{{
                total_contact.row.demo_y_num +
                "/" +
                total_contact.row.demo_percentage
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="percentage"
            label="评测达标量/达标率"
            width="180"
            align="center"
          >
            <template slot-scope="total_contact">
              <span>{{
                total_contact.row.evaluate_y_num +
                "/" +
                total_contact.row.evaluate_percentage
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="percentage"
            label="整体达标量/达标率"
            width="180"
            align="center"
          >
            <template slot-scope="total_contact">
              <span>{{
                total_contact.row.y_num + "/" + total_contact.row.percentage
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <h2>
        能力规范概况：<el-input
          v-model="search"
          @input="searching"
          placeholder="输入关键字搜索，支持搜索能力名称和团队名称"
          class="paas_search"
        />
      </h2>
      <div class="table_box">
        <el-table
          :data="data"
          border
          style="width: 100%"
          stripe
          :header-cell-style="{
            fontWeight: 600,
            color: '#FFF',
            background: '#5B8FF9',
          }"
        >
          <el-table-column prop="name" label="能力名称" width="180">
          </el-table-column>
          <el-table-column prop="team" label="团队名称"> </el-table-column>
          <el-table-column
            prop="doc_part"
            label="文档规范"
            width="140"
            align="center"
          >
            <template slot-scope="doc_part">
              <span class="part_n" v-if="doc_part.row.doc_part == 0"
                >未达标</span
              >
              <span class="part_y" v-if="doc_part.row.doc_part == 1">达标</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="interface_part"
            label="接口规范"
            width="140"
            align="center"
          >
            <template slot-scope="interface_part">
              <span class="part_n" v-if="interface_part.row.interface_part == 0"
                >未达标</span
              >
              <span class="part_y" v-if="interface_part.row.interface_part == 1"
                >达标</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="demo_part"
            label="体验规范"
            width="140"
            align="center"
          >
            <template slot-scope="demo_part">
              <span class="part_n" v-if="demo_part.row.demo_part == 0"
                >未达标</span
              >
              <span class="part_y" v-if="demo_part.row.demo_part == 1"
                >达标</span
              >
            </template>
          </el-table-column>
          <!-- <el-table-column prop="report_part" label="上报规范" width="140">
          </el-table-column> -->
          <el-table-column
            prop="evaluate_part"
            label="评测规范"
            width="140"
            align="center"
          >
            <template slot-scope="evaluate_part">
              <span class="part_n" v-if="evaluate_part.row.evaluate_part == 0"
                >未达标</span
              >
              <span class="part_y" v-if="evaluate_part.row.evaluate_part == 1"
                >达标</span
              >
            </template>
          </el-table-column>
          <!-- <el-table-column prop="atom_part" label="原子规范" width="140">
          </el-table-column> -->
          <el-table-column prop="contact" label="接口人" width="180">
          </el-table-column>
          <el-table-column prop="PaaS" label="PaaS链接" width="200">
            <template slot-scope="PaaS">
              <a
                :href="
                  'http://paas.woa.com/#/function-detail/' +
                  PaaS.row.function_id
                "
                target="_blank"
                title="点击跳转"
                >http://paas.woa.com/#/function-detail/{{
                  PaaS.row.function_id
                }}</a
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page_box">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="total_num"
          @current-change="current_change"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    // doc_part：文档部分是否达成规范
    // interface_part：接口部分是否达成规范
    // demo_part：体验是否达成规范
    // report_part：上报是否达成规范
    // evaluate_part：评测部分是否达成规范
    // atom_part：原子部分是否达成规范
    this.get_standard();
  },
  data() {
    return {
      windowWidth: true,
      total_num: 0,
      deposit_total_num: 0,
      share_total_num: 0,
      total_y_num: 0,
      total_standard: 0,
      tableData: [],
      paasData: [],
      data: [],
      pagesize: 10,
      search: "",
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    // 获取数据
    get_standard() {
      var standard_arr = []; // 总数据
      var share_arr = []; // 共享数据
      var deposit_arr = []; // 托管数据
      var team_standard_arr = [];
      this.axios
        .post("https://arc.tencent.com/QueryData/QueryTSR", {})
        .then((res) => {
          standard_arr = res.data.results.data;
          this.paasData = standard_arr;
          this.current_change(1);
          this.total_num = standard_arr.length;
          // 计算得出团队数组
          for (let i = 0; i < standard_arr.length; i++) {
            // 区分共享能力和托管能力
            if (standard_arr[i].share_type == "share") {
              this.share_total_num += 1;
              share_arr.push(standard_arr[i]);
            } else if (standard_arr[i].share_type == "deposit") {
              this.deposit_total_num += 1;
              deposit_arr.push(standard_arr[i]);
            }
            let isExist = false;
            for (let j = 0; j < team_standard_arr.length; j++) {
              if (team_standard_arr[j].team == standard_arr[i].team) {
                isExist = true;
              }
            }
            if (isExist == false) {
              let index = team_standard_arr.length + 1;
              let temp = {
                index: index,
                team: standard_arr[i].team,
                total_num: 0,
                y_num: 0,
                percentage: "0%",
                doc_y_num: 0,
                doc_percentage: "0%",
                interface_y_num: 0,
                interface_percentage: "0%",
                demo_y_num: 0,
                demo_percentage: "0%",
                report_y_num: 0,
                report_percentage: "0%",
                evaluate_y_num: 0,
                evaluate_percentage: "0%",
                atom_y_num: 0,
                atom_percentage: "0%",
              };
              team_standard_arr.push(temp);
            }
          }
          // 主计算
          for (let i = 0; i < standard_arr.length; i++) {
            // 计算总达标
            if (
              standard_arr[i].doc_part == 1 &&
              standard_arr[i].interface_part == 1
            ) {
              this.total_y_num += 1;
            }
            // 计算团队达标
            for (let j = 0; j < team_standard_arr.length; j++) {
              if (standard_arr[i].team == team_standard_arr[j].team) {
                team_standard_arr[j].total_num += 1;
                if (
                  standard_arr[i].doc_part == 1 &&
                  standard_arr[i].interface_part == 1
                ) {
                  team_standard_arr[j].y_num += 1;
                }
                if (standard_arr[i].doc_part == 1) {
                  team_standard_arr[j].doc_y_num += 1;
                }
                if (standard_arr[i].interface_part == 1) {
                  team_standard_arr[j].interface_y_num += 1;
                }
                if (standard_arr[i].demo_part == 1) {
                  team_standard_arr[j].demo_y_num += 1;
                }
                if (standard_arr[i].report_part == 1) {
                  team_standard_arr[j].report_y_num += 1;
                }
                if (standard_arr[i].evaluate_part == 1) {
                  team_standard_arr[j].evaluate_y_num += 1;
                }
                if (standard_arr[i].atom_part == 1) {
                  team_standard_arr[j].atom_y_num += 1;
                }
              }
            }
          }
          // 计算总体规范率
          this.total_standard = (
            (this.total_y_num / deposit_arr.length) *
            100
          ).toFixed(2);
          // 团队规范
          // 计算百分比
          for (let v = 0; v < team_standard_arr.length; v++) {
            // 整体百分比
            team_standard_arr[v].percentage =
              (
                (team_standard_arr[v].y_num / team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
            // 文档百分比
            team_standard_arr[v].doc_percentage =
              (
                (team_standard_arr[v].doc_y_num /
                  team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
            // 接口百分比
            team_standard_arr[v].interface_percentage =
              (
                (team_standard_arr[v].interface_y_num /
                  team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
            // demo百分比
            team_standard_arr[v].demo_percentage =
              (
                (team_standard_arr[v].demo_y_num /
                  team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
            // 上报百分比
            team_standard_arr[v].report_percentage =
              (
                (team_standard_arr[v].report_y_num /
                  team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
            // 评测百分比
            team_standard_arr[v].evaluate_percentage =
              (
                (team_standard_arr[v].evaluate_y_num /
                  team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
            // 原子百分比
            team_standard_arr[v].atom_percentage =
              (
                (team_standard_arr[v].atom_y_num /
                  team_standard_arr[v].total_num) *
                100
              ).toFixed(2) + "%";
          }
          // 排序
          for (let i = team_standard_arr.length - 1; i > 0; i--) {
            for (let j = 0; j < i; j++) {
              if (
                team_standard_arr[j].total_num <
                team_standard_arr[j + 1].total_num
              ) {
                let temp = {};
                Object.assign(temp, team_standard_arr[j]);
                for (let k in team_standard_arr[j + 1]) {
                  team_standard_arr[j][k] = team_standard_arr[j + 1][k];
                }
                for (let k in temp) {
                  team_standard_arr[j + 1][k] = temp[k];
                }
              }
            }
          }
          this.tableData = team_standard_arr;
        });
    },
    current_change(currentPage) {
      var temp_arr = this.paasData.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase()) ||
          data.team.toLowerCase().includes(this.search.toLowerCase())
      );
      this.data = temp_arr.slice(
        (currentPage - 1) * this.pagesize,
        currentPage * this.pagesize
      );
      var that = this;
      that.total_num = temp_arr.length;
    },
    searching() {
      this.current_change(1);
    },
  },
};
</script>

<style lang="less" scoped>
.GovernmentWeekly {
  width: 100%;
  font-size: 0.16rem;
  .wrap {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    h2 {
      width: 100%;
      margin: 20px 0 10px;
      font-size: 24px;
      line-height: 1.5;
      color: #515a6e;
    }
    .targetCard {
      width: 20%;
      height: 150px;
      background: #e0e9ef;
      margin-bottom: 10px;
      border-radius: 5%;
      .title {
        font-size: 16px;
        line-height: 1.2;
        color: #515a6e;
        text-align: center;
        margin: 30px 0 20px;
        font-family: "arial", "helvetica", "sans-serif";
        font-weight: 600;
      }
      .number {
        font-size: 40px;
        line-height: 1.2;
        color: #0e73ff;
        text-align: center;
        font-family: "arial", "helvetica", "sans-serif";
        font-weight: 600;
      }
    }
    .explain_box {
      width: 100%;
      margin-top: 10px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
      -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
      -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
      -o-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.07);
      p {
        position: relative;
        margin-left: 40px;
        font-family: "Verdana", "helvetica", "sans-serif";
        font-size: 14px;
        line-height: 2;
        letter-spacing: 1px;
        color: black;
      }
      .spec {
        margin-left: 15px;
      }
    }
    .table_box {
      width: 100%;
      .title {
        font-size: 16px;
        line-height: 1.5;
        color: #515a6e;
        font-family: "Verdana", "helvetica", "sans-serif";
      }
      .single {
        margin: 40px 0 20px;
      }
      .el-table {
        font-family: "Verdana", "helvetica", "sans-serif";
      }

      .part_n {
        color: #ee6666;
      }
      .part_y {
        color: #28a15a;
      }
    }
    .page_box {
      width: 100%;
      margin: 30px 0;
      text-align: left;
    }
    .paas_search {
      display: inline-block;
      width: 500px;
    }
  }
}
</style>